
<template>
  <div v-if="editorname">
    <template v-if="rewrited">
      <span>已处于重写流程中</span>
    </template>
    <template v-else>
      <Button v-if="editorname === self" @click="$emit('rewrite')">继续重写</Button>
      <span v-else>
        正在被
        <b>{{ editorname }}</b> 重写
      </span>
    </template>
  </div>
  <div v-else>
    <Button v-if="isOnshelf" @click="$emit('info')">重写</Button>
    <span v-else>
      <!-- 已下架不能重写 -->
    </span>
  </div>
</template>


<script>
export default {
  props: ["rewrited", "editorname", "isOnshelf"],
  computed: {
    self() {
      return this.$store.state.userInfo.realname; // username , realname
    }
  }
};
</script>

<style>
</style>